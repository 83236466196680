import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import nftix_logo from "../../assets/images/stellarclub.svg";
import onboardingclublogo from "../../assets/images/onboardingclublogo.svg";
import "./Loader.scss";

export default function Loader({ showLoading }) {
  return (
    <Modal
      open={showLoading}
      aria-labelledby="loader"
      aria-describedby="loading"
      className="align-middle justify-center items-center outline-none justify-items-center flex h-screen"
    >
      {/* <div className="outline-none">
        <img
          alt="logo"
          src={nftix_logo}
          className="animate-pulse align-middle justify-center m-auto w-96"
        />
      </div> */}
      <div className="outline-none">
        <img
          alt="logo"
          src={onboardingclublogo}
          className="animate-pulse align-middle justify-center m-auto w-96 mb-20"
        />
      </div>
      {/* <div class="loader">
        <span></span>
      </div> */}
    </Modal>
  );
}
